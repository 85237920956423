<template>
  <div class="admin-container">
    <template v-if="user">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn tile small @click="$router.push({ name: 'Users' })"
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field v-model="user.first_name" label="Jméno"></v-text-field
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.last_name"
            label="Příjmení"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-select
            :items="roles"
            v-model="user.role"
            label="Typ uživatele"
            item-text="name_loc"
            item-value="name"
            disabled
          ></v-select
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.phone_number"
            label="Telefonní číslo"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.email"
            disabled
            label="E-mail"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            small
            tile
            color="error"
            class="mr-4"
            @click="deleteDialog = true"
            >Odstranit</v-btn
          >
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="$router.push({ name: 'Users' })"
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="updateUser">Uložit</v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog
          v-model="deleteDialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline">Odstranit uživatele</v-card-title>
            <v-card-subtitle
              >Opravdu si přejete odstranit uživatele ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn
                    color="error"
                    small
                    tile
                    width="100%"
                    @click="deleteUser"
                    >Odstranit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="deleteDialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from "../plugins/bus";
import users from "../api/userService";
import authHandler from "../api/authHandler";
import userService from "../api/userService";

export default {
  data() {
    return {
      user: null,
      roles: [],
      deleteDialog: false,
    };
  },
  async created() {
    await this.getUser();
    await this.getRoles();
  },
  methods: {
    async getRoles() {
      try {
        this.roles = await userService.getRoles();
      } catch (error) {
        throw error;
      }
    },
    async deleteUser() {
      try {
        bus.$emit("processing");
        await userService.deleteUser(this.$route.params.userId);
        bus.$emit("processing", false);
        this.$router.push({ name: "Users" });
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (!authHandler(error)) this.$router.push({ name: "Login" });
        if (error.response && error.response.data) {
          bus.$emit("snackbar", {
            text: error.response.data.message,
            color: "error",
          });
        }
      }
    },
    async getUser() {
      try {
        bus.$emit("processing");
        const response = await users.getUser(this.$route.params.userId);
        this.user = response;
        bus.$emit("processing", false);
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (error.response && error.response.data) {
          bus.$emit("snackbar", {
            text: error.response.data.message,
            color: "error",
          });
        }
      }
    },
    async updateUser() {
      try {
        bus.$emit("processing");
        const response = await users.updateUser(
          this.$route.params.userId,
          this.user
        );
        console.debug("Response", response);
        bus.$emit("processing", false);
        bus.$emit("snackbar", {
          text: "Uloženo",
          color: "success",
        });
        this.$router.push({ name: "Users" });
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
      }
    },
  },
  watch: {},
};
</script>

<style></style>
